import React, { Component } from 'react';
import { Row, Col } from 'antd';

export default class ClientDetails extends Component {
  render() {
    const { client } = this.props;
    return <Row style={{ marginTop: '10px' }}>
    <Col span={12}  >
      <h4><b>Module Details</b></h4>
      <p><span className='sb-info-label'>Key: </span> {client.key}</p>
      <p><span className='sb-info-label'>Name: </span> {client.name}</p>
      <p><span className='sb-info-label'>Url: </span> {client.url} </p>
      <p><span className='sb-info-label'>Client ID: </span> {client.client_id}</p>
      <p><span className='sb-info-label'>Client Secret: </span> {client.client_secret}</p>
      <p><span className='sb-info-label'>Description: </span> </p>
      <p><br /></p>
      <p>{client.description}</p>
    </Col>
    <Col span={12}>
      <h4><b>Module Permissions</b></h4>
      <ul>
      {
        client.permissions.map((item, index)=> {
          return <li key={index}>{item.name}</li>
        })
      }
      </ul>
    </Col>
  </Row>

  }
}