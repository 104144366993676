import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Checkbox } from 'antd';


export default class CheckboxGroup extends Component {
    constructor(props) {
        super(props);
        const { value, options } = this.props;
        this.state = { 
            value: value || [],
            indeterminate: !value?false: (!!value.length && (value.length < options.length)),
            checkAll: !value?false:value.length === options.length,
        }
    }
      
    toggleOption = (option) => {
        const optionIndex = this.state.value.indexOf(option);
        const value = [...this.state.value];
        if (optionIndex === -1) {
            value.push(option);
        } 
        else {
            value.splice(optionIndex, 1);
        }

        if (this.props.hasCheckAll) {
            const options = this.props.options;
            this.setState({
                value,
                indeterminate: !!value.length && (value.length < options.length),
                checkAll: value.length === options.length,
            }); 
        }

        // if (!('value' in this.props)) {
        //     this.setState({ value });
        // }

        const onChange = this.props.onChange;
        if (onChange) {
            onChange(value);
        }
    }

    onCheckAllChange = (e) => {
        const options = this.props.options;
        const value = e.target.checked ? options.map ((names)=>names.name) : []
        this.setState({
            value,
            indeterminate: false,
            checkAll: e.target.checked,
        });

        const onChange = this.props.onChange;
        if (onChange) {
            onChange(value);
        }
    }

    render() {
        const { props, state } = this;
        const { prefixCls, className, style, options, hasCheckAll, checkAllLabel, label, disabled } = props;
        const groupPrefixCls = `${prefixCls}-group`;
        const children = [];
        if (hasCheckAll) {
            children.push(
                <Checkbox
                    disabled={disabled}
                    prefixCls={prefixCls}
                    key='Check All'
                    value='Check All'
                    indeterminate={state.indeterminate}
                    checked={state.checkAll}
                    onChange={this.onCheckAllChange}
                    className={`${groupPrefixCls}-item`}
                >
                    {checkAllLabel}
                </Checkbox>
            );
        }
        
        if (options && options.length > 0) {
            options.map(option => {
                children.push(
                    <Checkbox
                        disabled={disabled}
                        prefixCls={prefixCls}
                        key={option.name}
                        value={option.name}
                        checked={state.value.indexOf(option.name) !== -1}
                        onChange={() => this.toggleOption(option.name)}
                        className={`${groupPrefixCls}-item`}
                    >
                        {option.name}
                    </Checkbox>
                );
            });
        }
        const classString = classNames(groupPrefixCls, className);
        
        return (
            <div className={classString} style={style}>
                <h4><b>{label}</b></h4>
                {children}
            </div>
        );
    }
}

CheckboxGroup.propTypes = {
    options: PropTypes.array,
    defaultValue: PropTypes.array,
    value: PropTypes.array,
    hasCheckAll: PropTypes.bool,
    checkAllLabel: PropTypes.string,
    prefixCls: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool
}

CheckboxGroup.defaultProps = {
    prefixCls: 'ant-checkbox',
    checkAllLabel: 'Select All',
}
