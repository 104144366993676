import { API_LOGIN_TYPE, LOGOUT_TYPE } from '../actionCreators'
import { API_USER_UPDATE_PROFILE_ACTION } from 'modules/user/actionCreators'

export const loggedInUser = (state = null, action) => {
    switch (action.type) {
        case `${API_LOGIN_TYPE}_SUCCESS`:
            localStorage.setItem('token', action.payload.token);
            const { user, token, token_expiry } = action.payload;
            return { ...state, token, user, token_expiry }
        case `${API_USER_UPDATE_PROFILE_ACTION}_SUCCESS`:
            return { ...state, user: action.payload.user };
        case LOGOUT_TYPE:
            console.log('logout');
            localStorage.setItem('token', null);
            return null;
        default:
            const { type } = action;
            const matches = /(.*)_(FAILURE)/.exec(type);
            // handle 401 error globally and set loggedInUser to false
            if (matches) {
                if (action.payload.httpStatus === 401) {
                  console.log('need to login');
                  localStorage.setItem('token', null);
                  return null;
                }
            }
            return state;            
    }
}
