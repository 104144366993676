import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Row, Col, Modal, notification, Button, Icon} from 'antd';
import { user_update_profile } from '../actionCreators';
import { client_list } from 'modules/client/actions/client_actions';
import UserDetails from '../components/user_details';

const FormItem = Form.Item;

const formItemLayout = {
    labelCol: {
        md: { span: 24 },
    },
    wrapperCol: {
        md: { span: 24 },
    },
};

const WrappedForm = Form.create()(
  class extends Component {
    render() {
      const { visible, onCancel, onOk, form, user } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Modal
          width={800}
          title="Edit Profile"
          visible={visible}
          onOk={onOk}
          onCancel={onCancel}
        >
          <Form layout='vertical'>
            <Row gutter={20}>
              <Col span={12}><Row>
                <FormItem label="Email" {...formItemLayout}>
                  {
                    getFieldDecorator('email', {
                      rules: [],
                      initialValue: user ? user.email : ''
                    })(
                      <Input disabled/>
                    )
                  }
                </FormItem>
              </Row></Col>
              <Col span={12}><Row>
                <FormItem label="Password" {...formItemLayout}>
                  {
                    getFieldDecorator('password', {
                      rules: [{ required: user ? false: true , message: 'Please input!' }],
                    })(
                      <Input autoComplete="new-password" type="password"
                      placeholder={user ? 'Leave empty to keep password' : ''}/>
                    )
                  }
                </FormItem>
              </Row></Col>
            </Row>
            <Row gutter={20}>
              <Col span={24}><Row>
                <FormItem label="Name" {...formItemLayout}>
                  {
                    getFieldDecorator('name', {
                      rules: [{ required: true, message: 'Please input!' }],
                      initialValue: user ? user.name : ''
                    })(
                      <Input />
                    )
                  }
                </FormItem>
              </Row></Col>
            </Row>
          </Form>
        </Modal>
      );
    }
  }
)


// This is the wrapping component that handles accessing the form values
class EditProfile extends Component {
  constructor (props) {
    super(props);
    this.state = {visible: false};
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.formRef.props.form.validateFields((err, values) => {
      // create claims payload

      if (!err) {
          this.props.user_update_profile(values, this.submitCallback);
      }
    });
  }

  submitCallback = (data) => {
    // close modal and redirect
    if (data.user) {
      notification['success']({ message: 'Profile Updated!' });
      this.setState({visible: false});
    }
    else {
      notification['error']({ message: data.message });
    }
  }

  showModal = () => {
    this.setState({visible: true});
  }

  resetFields = () => {
    //console.log('resetFields');
    this.formRef.props.form.resetFields();
  }

  onCancel = () => {
    this.setState({visible: false});
  }

  componentDidMount() {
    this.props.client_list();
  }

  render() {
    const { user } = this.props;
    console.log(this.props.clients)
    return (
      <div>
        <h3><b>My Account</b>
          <Button style={{ float: 'right' }} type="primary" onClick={this.showModal}>
            <Icon type="edit" /> Edit</Button>
          <br /><br />
        </h3>
        <UserDetails user={user} clients={this.props.clients}/>
        <WrappedForm
          wrappedComponentRef={(formRef) => {this.formRef = formRef;} }
          visible={this.state.visible}
          onCancel={this.onCancel}
          onOk={this.handleSubmit}
          user={user}
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { 
    user: state.loggedInUser.user,
    clients: state.clientListReducer
  };
}
const mapDispatchToProps = {
  user_update_profile, client_list
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
