import { API_USER_LIST_ACTION } from '../actionCreators'

export const userListReducer = (state = {}, action) => {
  switch (action.type) {
    // case `${API_USER_LIST_ACTION}_REQUEST`:
    //    return { ...state, meta: action.payload };
    case `${API_USER_LIST_ACTION}_SUCCESS`:
      return { ...state, 
        data: action.payload.data, 
        meta: { ...state.meta, 
          total: action.payload.total,
          page: action.payload.page,
          take: action.payload.take,
          q: action.payload.q
        } 
      };
    default:
      return state;
  }
}