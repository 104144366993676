import React, { Component } from 'react';
import { connect } from 'react-redux';
import ClientForm from 'modules/client/components/client_form';
import ClientDetails from 'modules/client/components/client_details';
import { client_read, client_update } from '../actions/client_actions';
import { Button, Icon } from 'antd';
import {notification} from 'antd';

class ClientView extends Component {
  constructor(props) {
    super(props);
    this.state = { visible: false };
  }
  componentDidMount() {
    const { client_id } = this.props;
    this.props.client_read(client_id);
  }

  showModal = () => {
    this.setState({ visible: true });
  }
  handleCancel = () => {
    this.setState({ visible: false });
  }
  handleSubmit = (values) => {
    this.props.client_update(this.props.client_id, values, this.submitCallback);
  }
  submitCallback = (data) => {
    // close modal and redirect
    if (data.client) {
      notification['success']({ message: 'Module Successfully updated!' });
      this.setState({ visible: false });
    }
    else {
      notification['error']({ message: data.message });
    }
  }

  render() {
    const { client } = this.props;
    if (!client) {
      return <div></div>
    }
    return (
      <div>
        <h3><b>View Module Information</b>
          <Button style={{ float: 'right' }} type="primary" onClick={this.showModal}>
            <Icon type="edit" /> Edit</Button>
          <Button style={{ float: 'right', marginRight: '10px' }} >
            <a href="/#/modules"><Icon type="double-left" /> Back</a>
          </Button>
          <br /><br />
        </h3>
        
        <ClientDetails client={client} />
        <ClientForm visible={this.state.visible}
          onCancel={this.handleCancel}
          handleSubmit={this.handleSubmit}
          client={client}
          />

      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { 
    client_id: ownProps.match.params.id,
    client: state.clientReducer
  };
}
const mapDispatchToProps = {
  client_read, client_update
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientView);
