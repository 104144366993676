import React, { Component } from 'react';
import { connect } from 'react-redux';

import RequestResetPasswordForm from '../components/request_reset_password_form';
import ResetPasswordForm from '../components/reset_password_form';

import { Alert } from 'antd';
import { user_request_reset_password, user_reset_password, API_REQUEST_RESET_PASSWORD_ACTION, API_RESET_PASSWORD_ACTION } from '../actionCreators';

class ForgotPassword extends Component {
  constructor (props){
    super(props);
    this.state = {
      loading: false,
      email: null,
      error: null,
      show_reset: false,
      show_request: true
    }
  }

  handleSubmitRequestResetPassword = (values) => {
    this.props.user_request_reset_password(values, this.callback_RequestResetPassword);
    this.setState({email: values.email});
  }

  callback_RequestResetPassword = (data) => {
    console.log(data);
    if (data.success) {
      this.setState({show_reset: true, success: 'Check your email and input the Code to change your password'})
    }
    else {
      this.setState({error: data.message})
    }
  }

  handleSubmitResetPassword = (values) => {
    this.props.user_reset_password(values, this.callback_ResetPassword);
  }

  callback_ResetPassword = (data) => {
    if (data.success) {
      this.setState({show_reset: false, show_request: false, success: 'Password reset successful. Go back to login to reset your password'})
    }    
    else {
      this.setState({error: data.message, success: null})
    }

  }

  render() {
    const errorMessage = 
    <div>
        { this.state.error? <Alert message={this.state.error} type="error" />: '' }
        <br />
    </div>
    const successMessage = 
    <div>
        { this.state.success? <Alert message={this.state.success} type="success" />: '' }
        <br />
    </div>

    return (
      <div className="row">
      <div className="LoginPage">
        <div className="LogoWrapper">
          <img src={require('assets/png/logo.png')} 
            alt="logo"
            width="30%" 
            height="30%"/>
            <h1>NPO User Login<br /></h1>
        </div>
      </div>
              
      <div className="LoginPage">
          <div className="LoginForm">
            <div className="login-instructions">
              <h3>Please enter your email address
                  <br /> and we will send a code to reset
                  <br /> your password</h3>
            </div>
            
            { errorMessage }
            { successMessage }
            { !this.state.show_reset && this.state.show_request && <RequestResetPasswordForm handleSubmit={this.handleSubmitRequestResetPassword} loading={this.props.loading}/> }
            { this.state.show_reset && <ResetPasswordForm handleSubmit={this.handleSubmitResetPassword} loading={this.props.loading} email={this.state.email}/> }
            <br />
            <a href="#/login">Back to Login</a>

          </div>
        </div>
      </div>
        
    );
  }
}

const mapStateToProps = state => ({
  loading: state.loadingReducer[API_REQUEST_RESET_PASSWORD_ACTION] || state.loadingReducer[API_RESET_PASSWORD_ACTION]
})

const mapDispatchToProps = {
  user_request_reset_password, user_reset_password
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
