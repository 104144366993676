import React, { Component } from 'react';
import { Row, Col } from 'antd';
import _ from 'lodash';

export default class UserDetails extends Component {
  render() {
    const { user } = this.props;
    var clientsDiv;
    if (this.props.clients && this.props.clients.data) {
      clientsDiv = this.props.clients.data.map((item) => {
        if (item.key === 'ppcd' && !this.props.user.permissions.ppcd) {
          return null;
        }
        if (item.key === 'accounting' && !this.props.user.permissions.accounting) {
          return null;
        }
        return <li><a href={item.url}>{item.name}</a></li>
      })  
    }
    var permissionsDiv;
    if (this.props.clients && this.props.clients.data) {
      permissionsDiv = this.props.clients.data.map((item) => {
        return <Col span={6}>
            <h4>{item.name}</h4>
            {
              item.permissions.map((permission_item) => {
                if (user.permissions && _.includes(user.permissions[item.key] ,permission_item.name) ) {
                  return <li key={permission_item.name}>{permission_item.name}</li>
                }
                else {
                  // return <li key={permission_item.name}>{permission_item.name}-NO</li>
                  return null;
                }
              })
            }
          </Col>
      })  
    }
    console.log(this.props);
    return <div>
    <Row style={{ marginTop: '10px' }}>
      <Col span={12}  >
        <h4><b>General Information</b></h4>
        <p><span className='sb-info-label'>Name: </span> {user.name}</p>
        <p><span className='sb-info-label'>Email Address: </span> {user.email} </p>
      </Col>
      <Col span={12}>
        <h4><b>Division and Section</b></h4>
        <p><span className='sb-info-label'>Division: </span> {user.division}</p>
        <p><span className='sb-info-label'>Section: </span> {user.section} </p>
      </Col>
    </Row>
    <Row style={{ marginTop: '10px' }}>
      { this.props.clients && <h4><b>Modules</b></h4> }
      { clientsDiv }
    </Row>
    <Row style={{ marginTop: '10px' }}>
    { this.props.clients && <h4><b>Permissions</b></h4> }
      { permissionsDiv }
    </Row>
    </div>

  }
}