import API_CLIENT from 'shared/utils/api_client';
import { getError } from 'shared/utils/api_utils';
import { make_api_call } from 'shared/utils/api_utils';


export const API_LOGIN_TYPE = 'API_LOGIN';
export const LOGOUT_TYPE = 'LOGOUT';
export const API_REQUEST_RESET_PASSWORD_ACTION = 'API_REQUEST_RESET_PASSWORD_ACTION';
export const API_RESET_PASSWORD_ACTION = 'API_RESET_PASSWORD_ACTION';
export const API_GET_OAUTH_CODE = 'API_GET_OAUTH_CODE_ACTION';

export const login = ({ email, password }) => {
    return (dispatch) => {
        dispatch({ type: `${API_LOGIN_TYPE}_REQUEST` });
        API_CLIENT.login({ email: email, password: password })
            .then((response) => dispatch({type: `${API_LOGIN_TYPE}_SUCCESS`, payload: response.data }))
            .catch((error) => dispatch({ type: `${API_LOGIN_TYPE}_FAILURE`, payload: getError(error) }));
    }
}

export const logout = () => {
    return (dispatch) => {
        dispatch({ type: LOGOUT_TYPE, payload: null });
    }
}

export const user_request_reset_password = (body, callback=null) => {
  return (dispatch) => {
    dispatch({ type: `${API_REQUEST_RESET_PASSWORD_ACTION}_REQUEST` });
    // console.log(body);
    make_api_call(dispatch, 
      API_CLIENT.user_request_reset_password(body), 
      API_REQUEST_RESET_PASSWORD_ACTION, 
      callback
      );   
  }
}

export const user_reset_password = (body, callback=null) => {
  return (dispatch) => {
    dispatch({ type: `${API_RESET_PASSWORD_ACTION}_REQUEST` });
    // console.log(body);
    make_api_call(dispatch, 
      API_CLIENT.user_reset_password(body), 
      API_RESET_PASSWORD_ACTION, 
      callback
      );   
  }
}

export const get_oauth_code = (body, callback=null) => {
  return (dispatch) => {
    dispatch({ type: `${API_GET_OAUTH_CODE}_REQUEST` });
    // console.log(body);
    make_api_call(dispatch, 
      API_CLIENT.get_oauth_code(body), 
      API_GET_OAUTH_CODE, 
      callback
      );   
  }
}
