import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';

import UserList from 'modules/user/containers/UserList';
import UserView from 'modules/user/containers/UserView';
import Account from 'modules/user/containers/Account';

class User extends Component {
  render() {
    return (
      <div className="App">
        <div style={{ background: '#fff', padding: 24, minHeight: 500 }}>
          <HashRouter>
            <Switch>
              <Route path="/account" component={Account} />
              <Route path="/users/view/:id" component={UserView} />
              <Route path="/users" component={UserList} />
            </Switch>
          </HashRouter>
        </div>
      </div>
    );
  }
}

export default User;