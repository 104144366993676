import {API_CLIENT_READ_ACTION, API_CLIENT_UPDATE_ACTION, API_CLIENT_LIST_ACTION } from '../actions/client_actions'

export const clientListReducer = (state = {}, action) => {
  switch (action.type) {
    case `${API_CLIENT_LIST_ACTION}_SUCCESS`:
      return { ...state, 
        data: action.payload.data, 
        meta: { ...state.meta, 
          total: action.payload.total,
          page: action.payload.page,
          take: action.payload.take,
          q: action.payload.q
        } 
      };
    default:
      return state;
  }
}

export const clientReducer = (state = null, action) => {
  switch (action.type) {
    case `${API_CLIENT_READ_ACTION}_REQUEST`:
      return null;
    case `${API_CLIENT_READ_ACTION}_SUCCESS`:
      return action.payload;
    case `${API_CLIENT_UPDATE_ACTION}_SUCCESS`:
      return action.payload.client;
    default:
      return state;
  }
}