import API_CLIENT from 'shared/utils/api_client';
import { make_api_call } from 'shared/utils/api_utils';

export const API_CLIENT_CREATE_ACTION = 'API_CLIENT_CREATE_ACTION';
export const API_CLIENT_READ_ACTION = 'API_CLIENT_READ_ACTION';
export const API_CLIENT_UPDATE_ACTION = 'API_CLIENT_UPDATE_ACTION';
export const API_CLIENT_LIST_ACTION = 'API_CLIENT_LIST_ACTION';

export const client_create = (body, callback=null) => {
  return (dispatch) => {
    dispatch({ type: `${API_CLIENT_CREATE_ACTION}_REQUEST` });
    make_api_call(dispatch, 
      API_CLIENT.client_create(body), 
      API_CLIENT_CREATE_ACTION, 
      callback
      );
  }
}

export const client_read = (client_id, callback=null) => {
  return (dispatch) => {
    dispatch({ type: `${API_CLIENT_READ_ACTION}_REQUEST` });
    make_api_call(dispatch, 
      API_CLIENT.client_read(client_id), 
      API_CLIENT_READ_ACTION, 
      callback
      );   
  }
}

export const client_update = (client_id, body, callback=null) => {
  return (dispatch) => {
    dispatch({ type: `${API_CLIENT_UPDATE_ACTION}_REQUEST` });
    make_api_call(dispatch, 
      API_CLIENT.client_update(client_id, body), 
      API_CLIENT_UPDATE_ACTION, 
      callback
      );
  }
}

export const client_list = (meta, callback=null) => {
  return (dispatch) => {
    dispatch({ type: `${API_CLIENT_LIST_ACTION}_REQUEST` });
    make_api_call(dispatch, 
      API_CLIENT.client_list(meta), 
      API_CLIENT_LIST_ACTION, 
      callback
      );
  }
}
