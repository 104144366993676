import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Table, Button, Switch, Icon, Checkbox, Popover } from 'antd';
import UserForm from 'modules/user/components/user_form';
import { user_create, user_list, user_set_status } from '../actionCreators';
import { client_list } from 'modules/client/actions/client_actions';
import {notification} from 'antd';
import { get } from 'lodash';
import { INITIAL_PAGINATION, NO_PAGINATION } from 'shared/constants/definitions';
import SearchBar from 'shared/components/SearchBar';

class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      visible: false, 
      columnsToShow: [
        'email',
        'name',
        'division', 
        'section',
        'is_disabled'
      ]
    };
  }

  componentDidMount() {
    this.props.user_list(INITIAL_PAGINATION);
    this.props.client_list(NO_PAGINATION);
  }

  showModal = () => {
    this.setState({ visible: true });
  }
  handleCancel = (e) => {
    this.setState({ visible: false });
  }
  handleSubmit = (values) => {
    this.props.user_create ({...values}, this.submitCallback);
  }
  submitCallback = (data) => {
    // close modal and redirect
    if (data.user) {
      notification['success']({ message: 'User Created!' });
      this.setState({ visible: false });
      this.props.history.push(`/users/view/${data.user.id}`)
    }
    else {
      notification['error']({ message: data.message });
    }
  }

  handleListChange = (pagination, filters, sorter) => {
    //console.log('Various parameters', pagination, filters, sorter);
    this.props.user_list({
      ...this.props.userListReducer.meta,
      take: pagination.pageSize,
      page: pagination.current,
      sort_key: sorter.columnKey,
      sort_dir: sorter.order,
      ...filters
    });
  }

  toggleStatus = (id, checked) => {
    // console.log(id, checked);
    this.props.user_set_status(id, checked);
  }
  toggleStatusCallback = (data) => {
    console.log(data);
    this.props.user_list({...this.props.userListReducer.meta});
  }

  onSearch = (term) => {
    //console.log(term);

    this.props.user_list({ ...this.props.userListReducer.meta, ...INITIAL_PAGINATION, q: term });
  }

  columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: true,
      render: function(item, row) {
        return <div>
          <NavLink to={`/users/view/${row.id}`}>{item}</NavLink>
        </div>
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      render: function(item, row) {
        return <div>
          <NavLink to={`/users/view/${row.id}`}>{item}</NavLink>
        </div>
      }
    },
    {
      title: 'Division',
      dataIndex: 'division',
      sorter: true,
      render: function(item, row) {
        return <div>
          <NavLink to={`/users/view/${row.id}`}>{item}</NavLink>
        </div>
      }
    },
    {
      title: 'Section',
      dataIndex: 'section',
      sorter: true,
      render: function(item, row) {
        return <div>
          <NavLink to={`/users/view/${row.id}`}>{item}</NavLink>
        </div>
      }
    },
    {
      title: 'Status',
      dataIndex: 'is_disabled',
      render: (item, row) => {
        return row.is_disabled ? 
          <Switch size="small" defaultChecked={false} onChange={(checked) => this.toggleStatus(row.id, checked)}/>:
          <Switch size="small" defaultChecked={true} onChange={(checked) => this.toggleStatus(row.id, checked)}/>
      },
      filters: [
        { "text": "Disabled", value: 1 },
        { "text": "Active", value: 0 }
      ]
    },
  ];

  filteredColumns = () => {
    return this.columns.filter(column => this.state.columnsToShow.includes(column.dataIndex))
  }

  toggleColumn = (column) => {
      const columns = [...this.state.columnsToShow];
      const index = columns.indexOf(column);
      if (index >= 0) {
      columns.splice(index, 1);
      } else {
      columns.push(column);
      }
      this.setState({ columnsToShow: columns })
  }

  columnsPopOver = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {
          this.columns.map(column => {
            const checked = this.state.columnsToShow.includes(column.dataIndex);
            return (
              <div key={column.dataIndex}>
                <Checkbox
                  onChange={e => this.toggleColumn(column.dataIndex)}
                  checked={checked}
                >
                  { column.title }
                </Checkbox>
              </div>
            );
          })
        }
      </div>
    );
  }

  render () {
    const { userListReducer, auth } = this.props;
    if (!userListReducer.meta) {
      return <div>
      </div>
    }
    return (
      <div>
        <h3>User Management
        <div style={{ float: 'right', fontSize: '12px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Popover
            title='Configure Columns'
            content={this.columnsPopOver()}
            trigger='click'>
              <Button
                shape='circle'
                size='large'
                icon='setting'
              />
          </Popover>
          <Button style={{ margin: '10px' }} type="primary" onClick={this.showModal}><Icon type="plus" />New User</Button>
          <UserForm visible={this.state.visible} 
            onCancel={this.handleCancel}
            handleSubmit={this.handleSubmit}
        />
        </div>
        </h3>
        <SearchBar onSearch={this.onSearch} />
        <Table style={{marginTop: '20px'}}
          columns={this.filteredColumns()}
          dataSource={userListReducer.data}
          pagination={{ 
            defaultPageSize: 10, 
            showSizeChanger: true, 
            pageSizeOptions: ['10', '20', '30', '40','50'],
            position: 'bottom',
            total: get(this.props, 'meta.total', 0)
          }}
          onChange={this.handleListChange}
          rowKey="id"
          size="middle"
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { 
    userListReducer: state.userListReducer,
    auth: state.loggedInUser.user,
    clientReducer: state.clientListReducer
  };
}

const mapDispatchToProps = {
  user_create,
  user_list,
  user_set_status,
  client_list
}

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
