import React, { Component } from 'react';
import { Form, Input, Row, Col, Modal } from 'antd';
import { LAYOUT_1_COL_FORM_ITEM } from 'shared/constants/form_layouts';
import { MultipleInputField } from 'shared/components/acc-components';

const FormItem = Form.Item;
const TextArea = Input.TextArea;

const WrappedForm = Form.create()(
  class extends Component {
    render() {
      // console.log(this.props.client);
      const { visible, onCancel, onOk, form, client } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Modal
          width={800}
          title={client != null ? 'Edit Module': 'Add Module'}
          visible={visible}
          onOk={onOk}
          okText='Save'
          onCancel={onCancel}>
          
          <Form layout='vertical'>
            <Row>
              <Col span={24}>
                <FormItem label="Key" {...LAYOUT_1_COL_FORM_ITEM}>
                  {
                    getFieldDecorator('key', {
                      rules: [{ required: true, message: 'Please input!' }],
                      initialValue: client ? client.key : ''
                    })(
                      <Input disabled={client? true: false}/>
                    )
                  }
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <FormItem label="Name" {...LAYOUT_1_COL_FORM_ITEM}>
                  {
                    getFieldDecorator('name', {
                      rules: [{ required: true, message: 'Please input!' }],
                      initialValue: client ? client.name : ''
                    })(
                      <Input />
                    )
                  }
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <FormItem label="Url" {...LAYOUT_1_COL_FORM_ITEM}>
                  {
                    getFieldDecorator('url', {
                      rules: [{ required: true, message: 'Please input!' }],
                      initialValue: client ? client.url : ''
                    })(
                      <Input />
                    )
                  }
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <FormItem label="Description" {...LAYOUT_1_COL_FORM_ITEM}>
                  {
                    getFieldDecorator('description', {
                      rules: [{ required: true, message: 'Please input!' }],
                      initialValue: client ? client.description : ''
                    })(
                      <TextArea rows={4}/>
                    )
                  }
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <FormItem label="Permissions" {...LAYOUT_1_COL_FORM_ITEM}>
                  {
                    getFieldDecorator('permissions', {
                      rules: [{ required: true, message: 'Please input!' }],
                      initialValue: client ? client.permissions : null
                    })(
                      <MultipleInputField fields={[
                        {key: 'name', placeholder: 'Input Permission Name' }
                      ]} />
                    )
                  }
                </FormItem>
              </Col>
            </Row>


            

          </Form>
        </Modal>
      );
    }
  }
)


// This is the wrapping component that handles accessing the form values
export default class CasePartyClientForm extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.formRef.props.form.validateFields((err, values) => {
      if (!err) {
        const handleSubmit = this.props.handleSubmit;
        if (handleSubmit) {
          // console.log(values);
          handleSubmit(values);
        }
      }
    });
  }

  resetFields = () => {
    //console.log('resetFields');
    this.formRef.props.form.resetFields();
  }

  render() {
    const { onCancel, visible, client } = this.props;
    return (
      <div>
        <WrappedForm
          wrappedComponentRef={(formRef) => {this.formRef = formRef;} }
          visible={visible}
          onCancel={onCancel}
          onOk={this.handleSubmit}
          client={client}
        />
      </div>
    );
  }
}

