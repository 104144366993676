import React, { Component } from 'react';
import { Spin, notification, Row, Col } from 'antd';
import CheckboxGroup from 'shared/components/CheckboxGroup';
import debounce from 'lodash/debounce';
import API_CLIENT from 'shared/utils/api_client';


export default class MyComponent extends Component {

  constructor(props) {
    super(props);    
    const value = props.value || {};  
    this.state = {
      value: value,
      data: [],
      fetching: false,
      checked:false
    };
    this.lastFetchId = 0;
    this.fetchData = debounce(this.fetchData, 500);
  }

  // // for handling resetFields
  componentWillReceiveProps(props) {
    if ('value' in props) {
      this.setState({
          value: props.value || undefined,
      });
    }
  }

  componentDidMount = () => {
    this.fetchData('');
  }

  clear = () => {
    this.setState({value: undefined});
  }

  fetchData = (value) => {
    this.lastFetchId += 1;
    const fetchId = this.lastFetchId;
    this.setState({ data: [], fetching: true });
    API_CLIENT.client_list({ take: 10000 })
      .then((response) => {
        if (fetchId !== this.lastFetchId) { // for fetch callback order
          return;
        }
        this.setState({data: response.data.data, fetching: false});
      })
      .catch((error) => {
        // console.log(error);
        notification['error']({ message: error.message });
      });
  }

  handleChange = (key, value) => {
   
    var result = { ...this.props.value, [key]: value };
    console.log(result);
    if (this.props.onChange) {
      this.props.onChange(result);
    }
  }


  render() {
    var { data } = this.state;
    if (this.state.fetching) {
      return <Spin></Spin>
    }
  
    return (
      <Row>
        {
          data.map((item) => {  
            return (
              <Col span={8} key={item.key}>
                <h3>{item.name}</h3>
                <CheckboxGroup value={this.state.value? this.state.value[item.key]: null} 
                  onChange={(value) => { this.handleChange(item.key, value ) }}
                  options={item.permissions} hasCheckAll={true}> 
                </CheckboxGroup>
              </Col>
            )
          })
        }
      </Row>
    )
  }
}