import React, {Component} from "react";
import "./App.css";

import { HashRouter, Route, Switch } from 'react-router-dom';

import MainContainer from 'layouts/main';
import LandingContainer from 'layouts/landing';

class App extends Component {
  render() {
    return (
      <div className="App">
        <HashRouter>
          <Switch>
            <Route path="/oauth" component={LandingContainer} />
            <Route path="/oauth_code" component={LandingContainer} />
            <Route path="/logout" component={LandingContainer} />
            <Route path="/login" component={LandingContainer} />
            <Route path="/forgot_password" component={LandingContainer} />
            <Route path="/" component={MainContainer} />
          </Switch>
        </HashRouter>
      </div>

    );
  }
}

export default App;
