import { API_USER_READ_ACTION, API_USER_UPDATE_ACTION } from '../actionCreators'

export const userReducer = (state = null, action) => {
  switch (action.type) {
    case `${API_USER_READ_ACTION}_REQUEST`:
      return null;
    case `${API_USER_READ_ACTION}_SUCCESS`:
      return action.payload;
    case `${API_USER_UPDATE_ACTION}_SUCCESS`:
      return action.payload.user;
    default:
      return state;
  }
}