import React, { Component } from 'react';
import { Upload } from 'antd';
import { Icon } from 'antd';
import {getAuthHeader} from 'shared/utils/api_client';
const Dragger = Upload.Dragger; 

class FilesUpload extends Component {
    constructor(props) {
        super(props);
    
        const value = props.value || [];
        this.state = {
          value: value
        };
    }

    // // for handling resetFields
    componentWillReceiveProps(nextProps) {
      this.setState({
          value: nextProps.value || []
      });  
    }
    
    onDraggerChange = (info) => {
        console.log(info);
        const {fileList} = info;
        var filesSuccess = [];
        fileList.map(function(item){
            if (item.status === 'done') {
                filesSuccess.push({
                    uid: 0,
                    name: item.name,
                    status: item.status,
                    url: item.response.location,
                    response: '200'
                });
            }
            return null;
        });
        this.setState({value: filesSuccess});
        this.props.onChange(filesSuccess);
    }

    render() {
        //console.log(this.state.value);
        const uploadProps = {
            accept: '*',
            action: this.props.upload_url,
            multiple: true,
            headers: {...getAuthHeader() },
            data: {
            },
            listType: "text",
            onChange: this.onDraggerChange,
            defaultFileList: this.props.value
        }

        return <Dragger {...uploadProps} >
            <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
        </Dragger>
    }
}

export default FilesUpload;
