import API_CLIENT from 'shared/utils/api_client';
import { make_api_call } from 'shared/utils/api_utils';

export const API_USER_CREATE_ACTION = 'API_USER_CREATE_ACTION';
export const API_USER_READ_ACTION = 'API_USER_READ_ACTION';
export const API_USER_UPDATE_ACTION = 'API_USER_UPDATE_ACTION';
//export const API_USER_DELETE_ACTION = 'API_USER_DELETE_ACTION';
export const API_USER_SET_STATUS_ACTION = 'API_USER_SET_STATUS_ACTION';
export const API_USER_LIST_ACTION = 'API_USER_LIST_ACTION';
export const API_USER_UPDATE_PROFILE_ACTION = 'API_USER_UPDATE_PROFILE_ACTION';

export const user_create = (body, callback=null) => {
  return (dispatch) => {
    dispatch({ type: `${API_USER_CREATE_ACTION}_REQUEST` });
    make_api_call(dispatch, 
      API_CLIENT.user_create(body), 
      API_USER_CREATE_ACTION, 
      callback
      );
  }
}

export const user_read = (user_id, callback=null) => {
  return (dispatch) => {
    dispatch({ type: `${API_USER_READ_ACTION}_REQUEST` });
    make_api_call(dispatch, 
      API_CLIENT.user_read(user_id), 
      API_USER_READ_ACTION, 
      callback
      );
  }
}

export const user_update = (user_id, body, callback=null) => {
  return (dispatch) => {
    dispatch({ type: `${API_USER_UPDATE_ACTION}_REQUEST` });
    make_api_call(dispatch, 
      API_CLIENT.user_update(user_id, body), 
      API_USER_UPDATE_ACTION, 
      callback
      );
  }
}

export const user_update_profile = (body, callback=null) => {
  return (dispatch) => {
    dispatch({ type: `${API_USER_UPDATE_PROFILE_ACTION}_REQUEST` });
    make_api_call(dispatch, 
      API_CLIENT.user_update_profile(body), 
      API_USER_UPDATE_PROFILE_ACTION, 
      callback
      );
  }
}


export const user_list = (meta, callback=null) => {
  return (dispatch) => {
    dispatch({ type: `${API_USER_LIST_ACTION}_REQUEST`, payload: meta });
    make_api_call(dispatch, 
      API_CLIENT.user_list(meta), 
      API_USER_LIST_ACTION, 
      callback
      );
  }
}

export const user_set_status = (user_id, status, callback=null) => {
  return (dispatch) => {
    dispatch({ type: `${API_USER_SET_STATUS_ACTION}_REQUEST` });
    make_api_call(dispatch, 
      API_CLIENT.user_set_status(user_id, status), 
      API_USER_SET_STATUS_ACTION, 
      callback
      );
  }
}