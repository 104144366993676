import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';

import ClientList from './containers/ClientList';
import ClientView from './containers/ClientView';

class Client extends Component {
  render() {
    return (
      <div className="App">
        <div style={{ background: '#fff', padding: 24, minHeight: 500 }}>
          <HashRouter>
            <Switch>
              <Route path="/modules/view/:id" component={ClientView} />
              <Route path="/modules" component={ClientList} />
            </Switch>
          </HashRouter>
        </div>
      </div>
    );
  }
}

export default Client;