import React, { Component } from 'react';
import { Form, Icon, Input, Button } from 'antd';

const FormItem = Form.Item;

class ResetPasswordForm extends Component {
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const handleSubmit = this.props.handleSubmit;
                if (handleSubmit) {
                    handleSubmit(values);
                }
            }
        });
    }

    componentDidMount () {

      this.props.form.setFieldsValue({email: this.props.email});
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form onSubmit={this.handleSubmit} className="login-form">
                <FormItem className="InputWrapper">
                    {
                        getFieldDecorator('email', {
                            rules: [
                                { required: true, message: 'Please input your email',
                                initialValue: this.props.email
                               }
                            ],
                        })(
                            <Input disabled autoComplete="nope" prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email" />
                        )
                    }
                </FormItem>
                <FormItem className="InputWrapper">
                    {
                        getFieldDecorator('forgot_password_code', {
                            rules: [{ required: true, message: 'Please input your the code!' }],
                        })(
                            <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="text" placeholder="Code" />
                        )
                    }
                </FormItem>
                <FormItem className="InputWrapper">
                    {
                        getFieldDecorator('password', {
                            rules: [{ 
                              required: true,  
                              pattern: new RegExp(/^.*(?=.{3,})(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\d\x])(?=.*[!@$#%]).*$/),
                              message: "Password must have a minimum of 8 characters and must contain the following: \na. Numbers\nb. Letters (uppercase and lowercase)\nc. Special Characters (!, @, #, $, etc.)"
                            }],
                        })(
                            <Input autoComplete="new-password" prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Password" />
                        )
                    }
                </FormItem>
                
                <Button type="primary" htmlType="submit" className="login-form-button" 
                    loading={this.props.loading}>
                    Submit
                </Button>
            </Form>
        );
    }
}

export default Form.create()(ResetPasswordForm);
