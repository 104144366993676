import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { logout } from '../actionCreators';
import { get } from 'lodash';

class Logout extends Component {
    componentDidUpdate(prevProps) {
      this.doLogout();
    }

    componentDidMount() {
      this.doLogout();
    }

    doLogout = () => {
      if (this.props.loggedInUser) {
        this.props.logout();
      } 
    }

    render() {
      if (this.props.loggedInUser) {
        return (
            <span>
                Logging out...
            </span>
        );
      } else {
        if (!get(this, 'props.location.search', undefined) ) {
          return (
            <Redirect
              to={{
                pathname: '/login',
              }}
            />
          );
        }
        
        return (
          <Redirect
            to={{
              pathname: '/oauth',
              search: get(this, 'props.location.search', '')
            }}
          />
        );
      }
    }
}

const mapStateToProps = state => ({
    loginLoading: state.loginLoading,
    loggedInUser: state.loggedInUser,
    loginError: state.loginError
})

const mapDispatchToProps = {
    logout
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
