import {combineReducers} from 'redux'
import { loggedInUser } from 'layouts/landing/reducers/login_reducer';
import { loadingReducer } from 'layouts/main/reducers/loading_reducer';
import { errorReducer } from 'layouts/main/reducers/error_reducer';

import { userReducer } from 'modules/user/reducers/user_reducer';
import { userListReducer } from 'modules/user/reducers/user_list_reducer';

import { clientReducer, clientListReducer } from 'modules/client/reducers/client_reducer';

const appReducer = combineReducers({
    loggedInUser: loggedInUser,
    loadingReducer: loadingReducer,
    errorReducer: errorReducer,

    userReducer: userReducer,
    userListReducer: userListReducer,
    clientListReducer,
    clientReducer
});

// Clear all states after the user logs out
const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT') {
        console.log("LOGOUT: clearing global state...");
        state = undefined
    }
    return appReducer(state, action)
}

export default rootReducer;