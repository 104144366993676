import React, { Component } from 'react';
import { Form, Icon, Input, Button } from 'antd';

const FormItem = Form.Item;

class LoginForm extends Component {
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const handleSubmit = this.props.handleSubmit;
                if (handleSubmit) {
                    handleSubmit(values);
                }
            }
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form onSubmit={this.handleSubmit} className="login-form">
                <FormItem className="InputWrapper">
                    {
                        getFieldDecorator('email', {
                            rules: [
                                { required: true, message: 'Please input your email' }
                            ],
                        })(
                            <Input autoComplete="nope" prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email" />
                        )
                    }
                </FormItem>
                <FormItem className="InputWrapper">
                    {
                        getFieldDecorator('password', {
                            rules: [{ 
                              required: true, 
                              message: 'Please input your Password!' 
                            }],
                        })(
                            <Input autoComplete="new-password" prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Password" />
                        )
                    }
                </FormItem>
                
                <Button type="primary" htmlType="submit" className="login-form-button" 
                    loading={this.props.loading}>
                    Log in
                </Button>
            </Form>
        );
    }
}

export default Form.create()(LoginForm);
