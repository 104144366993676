import React, { Component } from 'react';
import _ from 'lodash';
import { Form, Select, Input, Row, Col, Modal } from 'antd';
import PermissionInput from './permission_input';

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
  labelCol: {
    md: { span: 24 },
  },
  wrapperCol: {
    md: { span: 24 },
  },
};

const DIVISION_SECTION_OPTIONS = [
  {
    "label": "Director's Office",
    "value": "DIRECTORS_OFFICE",
    "options": [
      { "label": "Director's Office", "value": "DIRECTORS_OFFICE"}
    ]
  },
  {
    "label": "Administrative Division",
    "value": "ADMINISTRATIVE_DIVISION",
    "options": [
      { "label": "HR", "value": "HR" },
      { "label": "ICT", "value": "ICT" },
      { "label": "Records", "value": "RECORDS" },
    ]
  },
  {
    "label": "Engineering Division",
    "value": "ENGINEERING_DIVISION",
    "options": [
      { "label": "Engineering Division", "value": "ENGINEERING_DIVISION" },
    ]
  },
  {
    "label": "Financial Management Division",
    "value": "FMD_DIVISION",
    "options": [
      { "label": "Accounting", "value": "ACCOUNTING" },
      { "label": "Budget", "value": "BUDGET" },
      { "label": "Cashier", "value": "CASHIER" },
    ]
  },
  {
    "label": "Sales Division",
    "value": "SALES_DIVISION",
    "options": [
      { "label": "Sales Division", "value": "SALES_DIVISION" },
    ]
  },
  {
    "label": "Production, Planning and Control Division",
    "value": "PPCD_DIVISION",
    "options": [
      { "label": "Cost Estimate", "value": "COST_ESTIMATE" },
      { "label": "Writer", "value": "WRITER" },
      { "label": "Raw Materials", "value": "RAW_MATERIALS" },
      { "label": "Control and Monitoring", "value": "CONTROL_AND_MONITORING" },
    ]
  },
  {
    "label": "Composing Division",
    "value": "COMPOSING_DIVISION",
    "options": [
      { "label": "Composing Division", "value": "COMPOSING_DIVISION" },
    ]
  },
  {
    "label": "Photolitography Division",
    "value": "PHOTOLITOGRAPHY_DIVISION",
    "options": [
      { "label": "Photolitography Division", "value": "PHOTOLITOGRAPHY_DIVISION" },
    ]
  },
  {
    "label": "Press Division",
    "value": "PRESS_DIVISION",
    "options": [
      { "label": "Press Division", "value": "PRESS_DIVISION" },
    ]
  },
  {
    "label": "Finishing Division",
    "value": "FINISHING_DIVISION",
    "options": [
      { "label": "Finishing Division", "value": "FINISHING_DIVISION" },
    ]
  },
]

const WrappedForm = Form.create()(
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {section_options: [], password_dirty: false};
    }

    onDivisionChange = (e) => {
      const value = e;
      var result = _.mapKeys(DIVISION_SECTION_OPTIONS, 'value')[value];
      this.setState({section_options: result.options});
      this.props.form.setFieldsValue({'section': result.options[0].value});
    }

    render() {
      const { visible, onCancel, onOk, form, user } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Modal
          width={800}
          title={user != null ? "Edit User" : "Create New User" }
          visible={visible}
          onOk={onOk}
          onCancel={onCancel}
        >
          <Form layout='vertical'>
            <Row gutter={20}>
              <Col span={12}>
                <Row>
                  <FormItem label="Email" {...formItemLayout}>
                    {
                      getFieldDecorator('email', {
                        rules: [{ required: true, message: 'Please input!' }],
                        initialValue: user ? user.email : ''
                      })(
                        <Input disabled={user? true: false}/>
                      )
                    }
                  </FormItem>
                </Row>
              </Col>
              <Col span={12}>
                <Row>
                  <FormItem label="Password" {...formItemLayout}>
                    {
                      getFieldDecorator('password', {
                        rules: [ !user || this.state.password_dirty ? { 
                          required: true, 
                          pattern: new RegExp(/^.*(?=.{3,})(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\d\x])(?=.*[!@$#%]).*$/),
                          message: "Password must have a minimum of 8 characters and must contain the following: \na. Numbers\nb. Letters (uppercase and lowercase)\nc. Special Characters (!, @, #, $, etc.)"
                        } : { required: false  } ],
                      })(
                        <Input onChange={() => this.setState({ password_dirty: true })} autoComplete="new-password" type="password" placeholder={user ? 'Leave empty to keep password' : ''}/>
                      )
                    }
                  </FormItem>
                </Row>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span={24}>
                <Row>
                  <FormItem label="Name" {...formItemLayout}>
                    {
                      getFieldDecorator('name', {
                        rules: [{ required: true, message: 'Please input!' }],
                        initialValue: user ? user.name : ''
                      })(
                        <Input />
                      )
                    }
                  </FormItem>
                </Row>
              </Col>
            </Row>
            <Row>
              <h3 style={{ margin: '15px 0px' }}><b>Division and Section</b></h3>
            </Row>
            <Row gutter={20}>
              <Col span={12}>
                <Row>
                  <FormItem label="Division" {...formItemLayout}>
                    {
                      getFieldDecorator('division', {
                        rules: [{ required: true, message: 'Please input!' }],
                        initialValue: user ? user.division : null
                      })(
                        <Select onChange={this.onDivisionChange} >
                          {
                            DIVISION_SECTION_OPTIONS.map((item)=> {
                              return <Option key={item.value} value={item.value}>{item.label}</Option>
                            })
                          }
                        </Select>
                      )
                    }
                  </FormItem>
                </Row>
              </Col>
              <Col span={12}>
                <Row>
                  <FormItem label="Section" {...formItemLayout}>
                    {
                      getFieldDecorator('section', {
                        rules: [{ required: true, message: 'Please input!' }],
                        initialValue: user ? user.section : null
                      })(
                        <Select >
                          {
                            this.state.section_options.map((item)=> {
                              return <Option key={item.value} value={item.value}>{item.label}</Option>
                            })
                          }
                        </Select>
                      )
                    }
                  </FormItem>
                </Row>
              </Col>
            </Row>
            <Row>
              <h3 style={{ margin: '15px 0px' }}><b>Permissions</b></h3>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <FormItem {...formItemLayout}>
                  {
                    getFieldDecorator('permissions', {
                      rules: [{ required: true, message: 'Please input!' }],
                      initialValue: user ? user.permissions : null
                    })(
                      <PermissionInput />
                    )
                  }
                </FormItem>
                
              </Col>
            </Row>

          </Form>
        </Modal>
      );
    }
  }
)


// This is the wrapping component that handles accessing the form values
export default class UserForm extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.formRef.props.form.validateFields((err, values) => {
      
      if (!err) {
        const handleSubmit = this.props.handleSubmit;
        if (handleSubmit) {
          handleSubmit(values);
        }
      }
    });
  }

  resetFields = () => {
    //console.log('resetFields');
    this.formRef.props.form.resetFields();
  }  

  render() {
    const { onCancel, visible, user } = this.props;
    return (
      <div>
        <WrappedForm
          wrappedComponentRef={(formRef) => {this.formRef = formRef;} }
          visible={visible}
          onCancel={onCancel}
          onOk={this.handleSubmit}
          user={user}
        />
      </div>
    );
  }
}

