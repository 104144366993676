export const LAYOUT_1_COL_FORM_ITEM = {
    labelCol: {
        md: { span: 6 },
    },
    wrapperCol: {
        md: { span: 18 },
    },
};

export const LAYOUT_2_COL_FORM_ITEM_1 = {
    labelCol: {
        md: { span: 12 },
    },
    wrapperCol: {
        md: { span: 12 },
    },
};

export const LAYOUT_2_COL_FORM_ITEM_2 = {
    labelCol: {
        md: { span: 10, push: 1 },
    },
    wrapperCol: {
        md: { span: 14 },
    },
};