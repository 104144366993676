import React, { Component } from 'react';
import { connect } from 'react-redux';

import _ from 'lodash';
import {login} from 'layouts/landing/actionCreators';

import { HashRouter, Route, Redirect, Switch } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { notification } from 'antd';
import { NavLink } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import User from 'modules/user';
import Client from 'modules/client';

const { Header, Content, Footer } = Layout;
const { SubMenu } = Menu;

notification.config({
  top: 50,
  right: 0,
  duration: 1.5,
});

class MainLayout extends Component {
    constructor(props) {
      super(props);
      this.state = {
        EditProfile_visible: false
      }
    }
  
    componentDidUpdate(prevProps) {
        if (this.props.loggedInUser == null) {
            this.props.history.push('/login');
        }
    }
    componentDidMount() {
        if (this.props.loggedInUser == null) {
            this.props.history.push('/login');
        }
    }

    render() {
        if(this.props.loggedInUser == null) {
          return <div>Logged out.</div>
        }
        var menu;
        menu = <Menu theme="light" mode="horizontal" style={{ lineHeight: '44px', float: 'left', border: '0px' }}>
            <Menu.Item key="1"><NavLink to='/account'>My Account</NavLink></Menu.Item>
            {
              _.get(this, 'props.loggedInUser.user.permissions.management', []).length > 0 
              && <Menu.Item key="2"><NavLink to='/users'>Users</NavLink></Menu.Item>
            }
            {
              _.get(this, 'props.loggedInUser.user.permissions.management', []).length > 0 
              && <Menu.Item key="3"><NavLink to='/modules'>Modules</NavLink></Menu.Item>
            }
            {
            //<SubMenu title="My Account">
            //    <Menu.Item><NavLink to='/account'>Edit Profile</NavLink></Menu.Item>
            //    <Menu.Item><NavLink to='/logout'>Logout</NavLink></Menu.Item>
            //</SubMenu>
            }
        </Menu>
        var topMenu;
        topMenu = <Menu theme="light" mode="horizontal" style={{ lineHeight: '44px', float: 'right', border: '0px', backgroundColor: "#467", color: 'white' }}>
            <SubMenu title="My Account" style={{ lineHeight: '44px' }}>
                <Menu.Item style={{ lineHeight: '44px'}}><NavLink to='/account'>My Account</NavLink></Menu.Item>
                <Menu.Item style={{ lineHeight: '44px' }}><NavLink to='/logout'>Logout</NavLink></Menu.Item>
            </SubMenu>
        </Menu>

        return (
          <div>
            <IdleTimer
                  timeout={900000}
                  onIdle={() => window.location = "/#/logout"}
            >
            {this.props.isLoading && <div id="cover-spin"></div>}
            <Layout className="layout">
                <Header style={{zIndex: 999, color: 'white', backgroundColor: "#467", height: '45px', 
                    lineHeight: '45px'}}>
                    <span style={{ color: 'white' }}>
                        <img alt="" height="30" style={{ margin: '4px', marginRight: "10px" }} src="/favicon.png" />
                        User</span>
                    {topMenu}
                </Header>
                <Header style={{ width: '100%', backgroundColor: "#fff", zIndex: 1000, 
                    borderBottom: '1px solid #eee', height: '45px', lineHeight: '45px' }}>
                    <div className="logo" />
                    {menu}
                </Header>

                <Content style={{ marginTop: '0px', padding: '0px 50px 0px 50px' }}>
                    <p style={{ float: 'right', padding: '10px', margin: '0px' }}></p>
                </Content>
                <Content style={{ padding: '0px 50px 0px 50px', marginTop: '0px' }}>
                    { 
                        //this.props.error && <Alert style={{marginBottom: '20px'}} type="error" message={this.props.error.message} banner closable showIcon/>
                    }
                                        
                        <HashRouter>
                            <Switch>
                                <Route path="/account" component={User} />
                                <Route path="/user" component={User} />
                                <Route path="/users" component={User} />
                                <Route path="/modules" component={Client} />
                                <Redirect path="/" to="/account" />
                            </Switch>
                        </HashRouter>

                    
                </Content>
                <Footer style={{ textAlign: 'center' }}>
                    &sdot;  User Management
                </Footer>
            </Layout>
          </IdleTimer>
        </div>
        );
    }
}

const mapStateToProps = state => ({
    loggedInUser: state.loggedInUser,
    isLoading: _.some(state.loadingReducer, function(item){
        return item === true;
    })
})

const mapDispatchToProps = {
    login
}

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);