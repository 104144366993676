import React, { Component } from 'react';
import { Input, Row, Col, Icon } from 'antd';
// import _ from 'lodash';
/*
Sample usage: 
<FormItem label="Address" {...LAYOUT_1_COL_FORM_ITEM}>
{
  getFieldDecorator('address', {
    rules: [{ required: true, message: 'Please input!' }]
  })(
    <MultipleInputField fields={[
      {key: 'line1', placeholder: 'Input Line1' },
      {key: 'line2'}
    ]} />
  )
}
</FormItem>
*/

class MultipleInputField extends Component {

    constructor(props) {
        super(props);    
        const value = props.value || [{}];
        this.state = {
          value: value
        };
    }

    componentDidUpdate(prevProps) {
    }

    // // for handling resetFields
    componentWillReceiveProps(nextProps) {
      this.setState({
          value: nextProps.value || [{}]
      });  
    }

    onChange = (index, key, e) => {
      var value=this.state.value;

      value[index][key] = e.target.value;
      this.setState({value: value});
      // TODO remove empty before returning
      
      this.props.onChange(value);
    }

    addMore = () => {
      var value=this.state.value;
      value.push({});
      this.setState({value: value});
    }

    onClose = (index) => {
      var value=this.state.value;
      value.splice(index, 1);
      this.setState({value: value});
      this.props.onChange(value);
    }

    render() {
        const { fields } = this.props;
        return <div>

          {
            this.state.value.map( (item, index) => {
              return <span key={index}>
                {
                  fields.map( (field) => {
                    return <Row>
                      <Col span={22}>
                        <Input key={field.key} style={{ marginBottom: 10}} 
                        value={this.state.value[index][field.key]} 
                        onChange={this.onChange.bind(this, index, field.key)} 
                        placeholder={field.placeholder}/>
                      </Col>
                      <Col span={2} style={{paddingTop: 6}} >
                        <Icon type="close" style={{ color: "red" }} onClick={() => {this.onClose(index)}} />
                    </Col>
                      </Row>
                  })
                }
              </span>
            })
          }
          {
            !this.props.single &&
            <a onClick={this.addMore}>+ Add another</a>

          }
        </div>
    }
}

export default MultipleInputField;
