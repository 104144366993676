import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { login, API_LOGIN_TYPE } from '../actionCreators';
import LoginForm from '../components/login_form';
import { Alert } from 'antd';

class Login extends Component {

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    console.log(query);
    this.setState({
      redirect_uri: query.redirect_uri,
      client_id: query.client_id
    })
  }
  
  componentDidUpdate(prevProps) {
    if (this.props.oauth) {
      if(this.props.loggedInUser != null) {
        var destination = '/oauth_code' + this.props.location.search;
        console.log(destination);
        this.props.history.push(destination);        
      }
    }
    else if (this.props.loggedInUser != null) {
      this.props.history.push('/account');
    }
  }

  handleSubmit = (values) => {
    this.props.login(values);
  }

  render() {
    const errorMessage = 
    <div>
        { this.props.loginError? <Alert message={this.props.loginError.message} type="error" />: '' }
        <br />
    </div>

    return (
      <div className="row">
      <div className="LoginPage">
        <div className="LogoWrapper">
          <img src={require('assets/png/logo.ico')} 
            alt="logo"
            width="30%" 
            height="30%"/>
            <h1>User Login<br /></h1>
        </div>
      </div>
              
      <div className="LoginPage">
          <div className="LoginForm">
            <div className="login-instructions">
              <h3>Please enter your email address<br />and password to login.</h3>
            </div>
            
            { errorMessage }
            <LoginForm handleSubmit={this.handleSubmit} loading={this.props.loginLoading}/>
            <br />
            <a href="#/forgot_password">Forgot password?</a>

          </div>
        </div>
      </div>
        
    );
  }
}

const mapStateToProps = state => ({
    loginLoading: state.loadingReducer[API_LOGIN_TYPE],
    loginError: state.errorReducer[API_LOGIN_TYPE],
    loggedInUser: state.loggedInUser,
})

const mapDispatchToProps = {
    login
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
