import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { API_GET_OAUTH_CODE,  get_oauth_code} from '../actionCreators';
import { Alert } from 'antd';

// http://localhost:3000/#/oauth?redirect_uri=http%3A%2F%2Flocalhost%3A3001%2F%23%2Flogin&client_id=e01ZAfYTTh6yGIPn
class OauthCode extends Component {
  
  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    console.log(query);
    this.props.get_oauth_code({
      redirect_uri: query.redirect_uri,
      client_id: query.client_id
    }, this.callback);
  }
  
  callback = (data) => {
    const query = queryString.parse(this.props.location.search);
    if (data.code) {
      window.location.href = query.redirect_uri + '?code=' + data.code;
    }
  }


  render() {
    const errorMessage = 
    <div>
        { this.props.error? <Alert message={this.props.error.message} type="error" />: '' }
        <br />
    </div>

    return (
      <div>
      
      {errorMessage}</div>              
        
    );
  }
}

const mapStateToProps = state => ({
    error: state.errorReducer[API_GET_OAUTH_CODE]
})

const mapDispatchToProps = {
  get_oauth_code
}

export default connect(mapStateToProps, mapDispatchToProps)(OauthCode);
