import React, { Component } from 'react';
import { connect } from 'react-redux';
import UserForm from 'modules/user/components/user_form';
import UserDetails from 'modules/user/components/user_details';
import { user_read, user_update } from '../actionCreators';
import { Button, Icon } from 'antd';
import {notification} from 'antd';
import { client_list } from 'modules/client/actions/client_actions';

class UserView extends Component {
  constructor(props) {
    super(props);
    this.state = { visible: false };
  }
  componentDidMount() {
    const { user_id } = this.props;
    this.props.user_read(user_id);
    this.props.client_list();
  }

  showModal = () => {
    this.setState({ visible: true });
  }
  handleCancel = () => {
    this.setState({ visible: false });
  }
  handleSubmit = (values) => {
    this.props.user_update(this.props.user_id, values, this.submitCallback);
  }
  submitCallback = (data) => {
    // close modal and redirect
    if (data.user) {
      notification['success']({ message: 'User Successfully updated!' });
      this.setState({ visible: false });
    }
    else {
      notification['error']({ message: data.message });
    }
  }

  render() {
    const { user } = this.props;
    if (!user) {
      return <div></div>
    }
    return (
      <div>
        <h3><b>View User Information</b>
          <Button style={{ float: 'right' }} type="primary" onClick={this.showModal}>
            <Icon type="edit" /> Edit</Button>
          <Button style={{ float: 'right', marginRight: '10px' }} >
            <a href="/#/users"><Icon type="double-left" /> Back</a>
          </Button>
          <br /><br />
        </h3>
        
        <UserDetails user={user} clients={this.props.clients}/>
        <UserForm visible={this.state.visible}
          onCancel={this.handleCancel}
          handleSubmit={this.handleSubmit}
          user={user}
          
          />

      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { 
    user_id: ownProps.match.params.id,
    user: state.userReducer,
    clients: state.clientListReducer
  };
}
const mapDispatchToProps = {
  user_read, user_update, client_list
}

export default connect(mapStateToProps, mapDispatchToProps)(UserView);
