import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Table, Button, Icon } from 'antd';
import ClientForm from '../components/client_form';
import { client_create, client_list } from '../actions/client_actions';
import {notification} from 'antd';
import { INITIAL_PAGINATION } from 'shared/constants/definitions';
import SearchBar from 'shared/components/SearchBar';

class ClientList extends Component {
  constructor(props) {
    super(props);
    this.state = { visible: false };
  }

  componentDidMount() {
    this.props.client_list(INITIAL_PAGINATION);
  }

  showModal = () => {
    this.setState({ visible: true });
  }
  handleCancel = (e) => {
    this.setState({ visible: false });
  }
  handleSubmit = (values) => {
    this.props.client_create ({...values}, this.submitCallback);
  }
  submitCallback = (data) => {
    // close modal and redirect
    if (data.client) {
      notification['success']({ message: 'Client Created!' });
      this.setState({ visible: false });
      this.props.history.push(`/clients/view/${data.client.id}`)
    }
    else {
      notification['error']({ message: data.message });
    }
  }

  handleListChange = (pagination, filters, sorter) => {
    //console.log('Various parameters', pagination, filters, sorter);
    this.props.client_list({
      ...this.props.clientListReducer.meta,
      take: pagination.pageSize,
      page: pagination.current,
      sort_key: sorter.columnKey,
      sort_dir: sorter.order,
      ...filters
    });
  }



  onSearch = (term) => {
    //console.log(term);
    this.props.client_list({ ...this.props.clientListReducer.meta, ...INITIAL_PAGINATION, q: term });
  }

  render () {
    const columns = [
      {
        title: 'Key',
        dataIndex: 'key',
        sorter: true,
        render: function(item, row) {
          return <div>
            <NavLink to={`/modules/view/${row.id}`}>{item}</NavLink>
          </div>
        }
      },
      {
        title: 'Name',
        dataIndex: 'name',
        sorter: true,
        render: function(item, row) {
          return <div>
            <NavLink to={`/modules/view/${row.id}`}>{item}</NavLink>
          </div>
        }
      }, 
      {
        title: 'URL',
        dataIndex: 'url',
        sorter: true
      },
      {
        title: 'Client ID',
        dataIndex: 'client_id',
        sorter: true
      },
      {
        title: 'Client Secret',
        dataIndex: 'client_secret',
        sorter: true
      }
    ];
    const { clientListReducer } = this.props;
    if (!clientListReducer.meta) {
      return <div>
      </div>
    }
    return (
      <div>
        <h3>Module Management
        <span style={{ float: 'right', fontSize: '12px' }}>
          {
              <Button type="primary" onClick={this.showModal}><Icon type="plus" />New Module</Button>
          }
          <ClientForm visible={this.state.visible} 
            onCancel={this.handleCancel}
            handleSubmit={this.handleSubmit}
        />
        </span>
        </h3>
        <SearchBar onSearch={this.onSearch} />
        <Table style={{marginTop: '20px'}}
          columns={columns}
          dataSource={clientListReducer.data}
          pagination={{
            total: clientListReducer.meta.total,
            current: clientListReducer.meta.page,
            pageSize: clientListReducer.meta.take
          }}
          onChange={this.handleListChange}
          rowKey="id"
          size="middle"
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { 
    clientListReducer: state.clientListReducer,
    auth: state.loggedInUser.user
  };
}

const mapDispatchToProps = {
  client_create,
  client_list
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientList);
