import axios from 'axios';
import BASE_URL from 'shared/constants/base_url';

const TIMEOUT = 10000;
const instance = axios.create({
  baseURL: BASE_URL,
  timeout: TIMEOUT
});

export const getAuthHeader = function() {
  return { Authorization: 'Bearer ' + localStorage.getItem('token') };
}

const API_CLIENT = {
  getFileUploadUrl: function(case_id) {
    return BASE_URL + `/case/${case_id}/file`;
  },
  login: function(payload) {
    return instance.post('/user/login', payload);
  },
  // history
  history_list: function (params) {
    return instance.get(`/history`, { params: params, headers: { ...getAuthHeader() } });
  },
  // user
  user_list: function (params) {
    return instance.get(`/users`, { params: params, headers: { ...getAuthHeader() } });
  },
  user_create: function (payload) {
    return instance.post('/user', payload, { headers: {...getAuthHeader()} } );
  },
  user_read: function (user_id) {
    return instance.get(`/user/${user_id}`, { headers: { ...getAuthHeader() } });
  },
  user_update: function (user_id, payload) {
    return instance.put(`/user/${user_id}`, payload, { headers: { ...getAuthHeader() } });
  },
  user_update_profile: function (payload) {
    return instance.put(`/user/update_profile`, payload, { headers: { ...getAuthHeader() } });
  },
  user_set_status: function(user_id, status) {
    if (status) {
      return instance.post(`/user/${user_id}/enable`, {}, { headers: {...getAuthHeader()} } );
    }
    else {
      return instance.post(`/user/${user_id}/disable`, {}, { headers: {...getAuthHeader()} } );
    }
  },
  user_request_reset_password: function (payload) {
    return instance.post('/user/request_reset_password', payload, { headers: { } } );
  },
  user_reset_password: function (payload) {
    return instance.post('/user/reset_password', payload, { headers: { } } );
  },
  // clients 
  client_create: function (payload) {
    return instance.post('/client', payload, { headers: {...getAuthHeader()} } );
  },
  client_read: function (case_id) {
    return instance.get(`/client/${case_id}`, { headers: { ...getAuthHeader() } });
  },
  client_update: function (case_id, payload) {
    return instance.put(`/client/${case_id}`, payload, { headers: { ...getAuthHeader() } });
  },
  client_list: function (params) {
    return instance.get(`/clients`, { params: params, headers: { ...getAuthHeader() } });
  },
  //
  get_oauth_code: function (payload) {
    return instance.post('/oauth/get_code', payload, { headers: {...getAuthHeader()} } );
  },
  

}



export default API_CLIENT;